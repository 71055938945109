var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],ref:"container",staticClass:"fill-height"},[_c('v-tabs',{ref:"tabs",attrs:{"active-class":"white","background-color":"grey lighten-3","hide-slider":"","show-arrows":""}},[_c('v-tab',{attrs:{"to":{
                name: _vm.activeRoute('project-details'),
                params: {
                    project: _vm.localProject,
                    ...(_vm.localQuotes ? { quotes: _vm.localQuotes } : {}),
                    clients: _vm.localClients,
                    users: _vm.localUsers,
                },
            }}},[_vm._v(" Project Details ")]),(
                _vm.user.permissions.includes('readProjectQuotes') &&
                    !_vm.genericProject
            )?_c('v-tab',{attrs:{"disabled":!_vm.localQuotes,"to":{
                name: _vm.activeRoute('project-quotes'),
                params: {
                    project: _vm.localProject,
                    ...(_vm.localQuotes ? { projectQuotes: _vm.localQuotes } : {}),
                    clients: _vm.localClients,
                    users: _vm.localUsers,
                    settings: _vm.settings,
                },
            }}},[_vm._v(" Quotes ")]):_vm._e(),_c('v-tab',{attrs:{"to":{
                name: _vm.activeRoute('tasks-by-project'),
                params: {
                    projectId: _vm.localProject.id,
                    avoidEmptyUsers: false,
                    projectCollaborators: _vm.localProject.collaborators,
                },
            }}},[_vm._v(" Tasks ")]),(_vm.quoteToFollowUp)?_c('v-tab',{attrs:{"to":{
                name: _vm.activeRoute('project-quote-follow-up'),
                params: {
                    id: _vm.quoteToFollowUp.id,
                    quote: _vm.quoteToFollowUp,
                    settings: _vm.settings,
                },
            }}},[_vm._v(" Follow Up ")]):_vm._e(),(_vm.localProject)?_c('v-tab',{attrs:{"to":{
                name: _vm.activeRoute('project-reserves'),
                params: {
                    projectId: _vm.localProject.id,
                },
            }}},[_vm._v(" Reserves ")]):_vm._e(),(_vm.localProject)?_c('v-tab',{attrs:{"to":{
                name: _vm.activeRoute('project-purchases'),
                params: {
                    projectId: _vm.localProject.id,
                },
            }}},[_vm._v(" Purchases ")]):_vm._e(),(_vm.localProject && this.readPartNumbers)?_c('v-tab',{attrs:{"to":{
                name: _vm.activeRoute('project-part-numbers'),
                params: {
                    projectId: _vm.localProject.id,
                },
            }}},[_vm._v(" PART NUMBERS ")]):_vm._e(),(_vm.localProject)?_c('v-tab',{attrs:{"to":{
                name: _vm.activeRoute('project-work-orders'),
                params: {
                    projectId: _vm.localProject.id,
                    project: _vm.localProject,
                },
            }}},[_vm._v(" WO ")]):_vm._e(),(_vm.localProject)?_c('v-tab',{attrs:{"to":{
                name: _vm.activeRoute('project-bill-of-materials'),
                params: {
                    projectId: _vm.localProject.id,
                },
            }}},[_vm._v(" BOM ")]):_vm._e(),(_vm.localProject)?_c('v-tab',{attrs:{"to":{
                name: _vm.activeRoute('project-packing-lists'),
                params: {
                    project: _vm.localProject,
                },
            }}},[_vm._v(" PACKING LISTS ")]):_vm._e(),(!_vm.genericProject)?_c('v-tab',{attrs:{"to":{
                name: _vm.activeRoute('b2b-forecast'),
                params: {
                    projectId: _vm.localProject.id,
                },
            }}},[_vm._v(" B2B Forecast ")]):_vm._e(),(!_vm.genericProject)?_c('v-tab',{attrs:{"to":{
                name: _vm.activeRoute('project-invoices'),
                params: {
                    project: _vm.localProject,
                },
            }}},[_vm._v(" INVOICES ")]):_vm._e(),_c('v-tab',{attrs:{"to":{
                name: _vm.activeRoute('project-logs'),
                params: {
                    id: _vm.localProject.id,
                },
            }}},[_vm._v(" LOGS ")])],1),_c('v-card',[_c('v-card-text',{staticClass:"overflow-y-auto",style:(`height: ${_vm.height}px`)},[_c('router-view',{key:_vm.$route.fullPath})],1)],1),_c('Errors')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }